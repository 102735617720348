import React, { useEffect, useState } from 'react'
import classnames from 'classnames'

import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Collapse from '@material-ui/core/Collapse'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import _ from 'lodash'

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage'
import { onCurrentLocaleCustom } from '../../../../../../../../utils/locale'

import styles from './ClassLegendList.module.scss'

export default function ClassLegendList({
  hideParentToggle,
  hideLegendIndex,
  classTreeNodes,
  activeClassTreeNodeIds,
  onClassTreeNodeToggle,
  onClassTreeNodeShowTooltip
}) {
  const formatMessage = useFormatMessage()
  const [expandedNodeIds, setExpandedNodeIds] = useState([])
  const rootLevel = _.min(_.map(classTreeNodes, 'level'))

  useEffect(() => {
    if (hideParentToggle) {
      setExpandedNodeIds(_.map(classTreeNodes, 'id'));
    }
  }, [hideParentToggle, classTreeNodes]);

  const toggleTreeNode = (node) => {
    if (_.includes(expandedNodeIds, node.id)) {
      setExpandedNodeIds(_.without(expandedNodeIds, node.id))
    } else {
      setExpandedNodeIds([ ...expandedNodeIds, node.id ])
    }
  }

  const renderCheckbox = (node) => {
    if (hideParentToggle && !_.isEmpty(node.childrenIds)) {
      return null;
    }

    const toggled = _.includes(activeClassTreeNodeIds, node.id)

    return (
      <IconButton
        className={ styles.classListItemCheckControl }
        style={{ color: node.color }}
        onClick={ _.partial(onClassTreeNodeToggle, node) }
      >
        { toggled ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> }
      </IconButton>
    )
  }

  const renderExpandIcon = (node) => {
    const expanded = _.includes(expandedNodeIds, node.id)
    const icon = expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />

    if (!_.isEmpty(_.get(node, 'childrenIds'))) {
      return (
        <IconButton
          className={ styles.classListItemContentControl }
          onClick={ _.partial(toggleTreeNode, node) }
        >
          { icon }
        </IconButton>
      )
    }
  }

  const renderTooltipIcon = (node) => {
    const tooltips = _.get(node, 'classTreeNode.classe.classesTooltips')
    if (!_.isEmpty(tooltips)) {
      return (
        <Tooltip title={ formatMessage('mapbiomas.header.classes_control.tooltip.info') }>
          <IconButton
            className={ styles.classListItemInfoControl }
            onClick={ _.partial(onClassTreeNodeShowTooltip, node) }
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )
    }
  }

  const renderClassTreeBranch = ({ parentId = null }) => {
    const parentNode = _.find(classTreeNodes, { id: parentId })
    const branchNodes = _.filter(classTreeNodes, { parentId })
    const currentLevelShiftedToZeroBased = (
      0 - (rootLevel) + (_.get(parentNode, 'level', rootLevel))
    )

    return (
      <ul
        className={ classnames(styles.classNode, {
          [styles.classChildNode]: !_.isNil(parentId)
        }) }
      >
        { _.map(_.sortBy(branchNodes, _.flow(_.property('positionInTree'), _.last)), (node) => {
          const prettyIndexOfNodeInTree = _.join(node.positionInTree, '.')
          const indexText = hideLegendIndex ? '' : `${ prettyIndexOfNodeInTree }.`;

          return (
            <li
              key={ `class-item-level-${ node.id }` }
              className={ styles.classListItemBase }
            >
              <div className={ styles.classListItemHeader }>
                { renderCheckbox(node) }
                <span
                  style={{ fontSize: 13 - currentLevelShiftedToZeroBased }}
                  className={ styles.classListItemTitle }
                >
                  {indexText} {onCurrentLocaleCustom(node.i18nStrings) }
                </span>
                { renderExpandIcon(node) }
                { renderTooltipIcon(node) }
              </div>
              <Collapse in={ _.includes(expandedNodeIds, node.id) }>
                <div>
                  { renderClassTreeBranch({ parentId: node.id }) }
                </div>
              </Collapse>
            </li>
          )
        }) }
      </ul>
    )
  }

  return (
    <div>
      { renderClassTreeBranch({ parentId: null }) }
    </div>
  )
}
