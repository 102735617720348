export default [
  {
    index: 0,
    key: "biome",
    labelKey: "biome",
    color: "#eb2f06",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:biome"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IBGE (1:250.000)' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/refined_biome.zip'
  },
  {
    index: 1,
    key: "state",
    labelKey: "state",
    color: "#FBEC00",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:state"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IBGE' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/state.zip'
  },
  {
    index: 2,
    key: "municipality",
    labelKey: "municipality",
    color: "#fdbf6f",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:municipality"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IBGE' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/city.zip'
  },
  {
    index: 3,
    key: "pnrh_level_1_basin",
    labelKey: "pnrh_level_1_basin",
    color: "#2980B9",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:pnrh_level_1_basin"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'ANA' },
      { labelKey: 'year', label: 'Ano', value: '2006' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/pnrh_level_1_basin.zip'
  },
  {
    index: 4,
    key: "pnrh_level_2_basin",
    labelKey: "pnrh_level_2_basin",
    color: "#55f8f8",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:pnrh_level_2_basin"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'ANA' },
      { labelKey: 'year', label: 'Ano', value: '2006' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/pnrh_level_2_basin.zip'
  },
  {
    index: 5,
    key: "legal_amazon",
    labelKey: "legal_amazon",
    color: "#A25E7D",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:legal_amazon"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'INPE - Terrabrasilis' },
      { labelKey: 'year', label: 'Ano', value: '2016' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/legal_amazon.zip'
  },
  {
    index: 6,
    key: "amacro",
    labelKey: "amacro",
    color: "#AF175D",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:amacro"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Municípios do IBGE 1:250.000' },
      { labelKey: 'year', label: 'Ano', value: '2021' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/amacro.zip'
  },
  {
    index: 7,
    key: "atlantic_forest_law",
    labelKey: "atlantic_forest_law",
    color: "#780439",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:atlantic_forest_law"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'SOS Mata Atlântica (refinado IBGE 1:5.000.000 com base no RADAM 1:1.000.000)' },
      { labelKey: 'year', label: 'Ano', value: '2016' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/atlantic_forest_law.zip'
  },
  {
    index: 8,
    key: "matopiba",
    labelKey: "matopiba",
    color: "#5A032A",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:matopiba"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'EMBRAPA' },
      { labelKey: 'year', label: 'Ano', value: '2015' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/matopiba.zip'
  },
  {
    index: 9,
    key: "semiarid",
    labelKey: "semiarid",
    color: "#360219",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:semiarid"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'ANA' },
      { labelKey: 'year', label: 'Ano', value: '2017' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/semiarid.zip'
  },
  {
    index: 10,
    key: "biosphere_reserve",
    labelKey: "biosphere_reserve",
    color: "#5BFC8C",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:biosphere_reserve"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MMA' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/biosphere_reserves.zip'
  },
  {
    index: 11,
    key: "geopark",
    labelKey: "geopark",
    color: "#ACFC3A",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:geopark"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'UNESCO' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/geoparks.zip'
  },
  {
    index: 12,
    key: "indigenous_territory",
    labelKey: "indigenous_territory",
    color: "#6a3d9a",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:indigenous_territory"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'FUNAI' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/indigenous_territories.zip'
  },
  {
    index: 13,
    key: "settlement",
    labelKey: "settlement",
    color: "#FC3AD3",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:settlement"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'INCRA' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/settlements.zip'
  },
  {
    index: 14,
    key: "quilombo",
    labelKey: "quilombo",
    color: "#7f6b83",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:quilombo"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IBGE' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/quilombos.zip'
  },
  {
    index: 15,
    key: "federal_protected_area_integral_protection",
    labelKey: "federal_protected_area_integral_protection",
    color: "#5AD34A",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:federal_protected_area_integral_protection"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'ICMBio' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/federal_protected_area_integral_protection.zip'
  },
  {
    index: 16,
    key: "federal_protected_area_sustainable_use",
    labelKey: "federal_protected_area_sustainable_use",
    color: "#23AA11",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:federal_protected_area_sustainable_use"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'ICMBio' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/federal_protected_area_sustainable_use.zip'
  },
  {
    index: 17,
    key: "state_protected_area_integral_protection",
    labelKey: "state_protected_area_integral_protection",
    color: "#178009",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:state_protected_area_integral_protection"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MMA' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/state_protected_area_integral_protection.zip'
  },
  {
    index: 18,
    key: "state_protected_area_sustainable_use",
    labelKey: "state_protected_area_sustainable_use",
    color: "#115B07",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:state_protected_area_sustainable_use"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MMA' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/state_protected_area_sustainable_use.zip'
  },
  {
    index: 19,
    key: "municipality_protected_area_integral_protection",
    labelKey: "municipality_protected_area_integral_protection",
    color: "#6FC164",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:municipality_protected_area_integral_protection"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MMA' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/municipality_protected_area_integral_protection.zip'
  },
  {
    index: 20,
    key: "municipality_protected_area_sustainable_use",
    labelKey: "municipality_protected_area_sustainable_use",
    color: "#408137",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-brasil:municipality_protected_area_sustainable_use"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MMA' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/layers/municipality_protected_area_sustainable_use.zip'
  },
  {
    index: 21,
    key: "car",
    labelKey: "car",
    color: "#636363",
    props: {
      url: 'https://shared-geoserver.mapbiomas.org/geoserver/gwc/service/wmts?layer=mapbiomas-alerta%3Amv_sfb_car&style=&tilematrixset=EPSG%3A3857&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=EPSG%3A3857%3A{z}&TileCol={x}&TileRow={y}',
      transparent: true,
      format: 'image/png',
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Serviço Florestal Brasileiro' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    // downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Adashboard_rural-properties-static-layer&outputFormat=SHAPE-ZIP'
  },
];
