import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import LineChart from '../../components/LineChart';
import PieChart from '../../components/PieChart';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import customClient from '../../../../../../../../core/custom-apollo-client/custom-apollo-client';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
} from './query';

export default function CoverageMainStatisticsContent({
  activeYear,
  baseParams,
  chartType,
  clientType,
  classTreeKey,
  statisticsDataIsVisible,
  onShowData,
  unit,
  hasSingleHistoricalData,
  hasCustomCOSValues,
  valueMantissa = 0,
}) {
  const currentYear = _.isArray(activeYear) ? _.last(activeYear) : activeYear;
  const locale = localStorage.getItem('locale') || 'pt-BR';
  const formatMessage = useFormatMessage();

  const [chartsData, setChartsData] = useState(null);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
    client: clientType === 'custom' ? customClient : undefined
  });
  const classTreeId = _.get(classTreeData, 'classTreeByKey[0].id');

  const crossingEnabled = _.get(baseParams, 'crossingEnabled');
  const crossingTerritories = _.get(baseParams, 'crossingTerritories');
  let territories = _.get(baseParams, 'territories');

  if (crossingEnabled && !_.isEmpty(crossingTerritories)) {
    territories = crossingTerritories;
  }

  const territoryIds = _.map(territories, 'id');

  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');
  const { data: baseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      territoryIds: territoryIds,
      classTreeId: classTreeId
    },
    skip: _.isEmpty(territoryIds) || !classTreeId || loadingClassTreeData,
    client: clientType === 'custom' ? customClient : undefined
  });

  const findFurthestParent = (node) => {
    const { parentId } = node;

    if (parentId) {
      const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
      const parentNode = _.find(classesLevelsList, { id: parentId });

      if (parentNode) {
        return _.get(parentNode, 'parentId') ? findFurthestParent(parentNode) : parentNode;
      }
    } else {
      return node;
    }
  };

  const findFurthestChildrenIds = (childrenIds = []) => {
    if (!_.isEmpty(childrenIds)) {
      const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

      const ids = _.map(childrenIds, (id) => {
        const node = _.find(classesLevelsList, { id });

        return findFurthestChildrenIds(_.get(node, 'childrenIds'));
      });

      return [
        ...childrenIds,
        ...(_.compact(_.flattenDeep(ids)))
      ]
    } else {
      return _.compact(_.flattenDeep(childrenIds));
    }
  };

  const parseChartsData = () => {
    let classViewChartDataUnit = null;
    let historicalSeriesChartDataUnit = null;
    const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
    const activeClassesLevelsList = _.filter(classesLevelsList, (item) => _.includes(activeClassTreeNodeIds, item.id));
    const coverageClassificationDataList = _.get(baseData, 'coverageClassificationDataList');
    const aggregatedCoverageClassificationDataList = _.values(_.reduce(coverageClassificationDataList, (result, obj) => {
      const key = `${ obj.year }-${ obj.classTreeNodeId }`;
      const nextValue = result[key] ? result[key].areaInHa + obj.areaInHa : obj.areaInHa;

      result[key] = {
        ...obj,
        areaInHa: nextValue
      };

      return result;
    }, {}));

    const parsedData = _.filter(_.map(aggregatedCoverageClassificationDataList, (item) => {
      const classData = _.find(classesLevelsList, { id: item.classTreeNodeId });

      return {
        year: Number(_.replace(item.year, '_', '')),
        areaInHa: item.areaInHa,
        classData,
      };
    }), 'classData');
    const years = _.sortBy(_.uniq(_.map(parsedData, 'year')));

    const parsedClassesLevelsList = _.map(activeClassesLevelsList, (classData) => {
      const stringList = _.get(classData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const className = `${ _.join(_.get(classData, 'positionInTree'), '.') }. ${ _.get(selectedString, 'stringValue') }`;

      return {
        ...classData,
        name: className,
        data: _.map(years, (year) => {
          const coverageClassificationData = _.find(parsedData, (item) => {
            return item.year === year &&
              item.classData.id === classData.id;
          });

          return coverageClassificationData ? _.get(coverageClassificationData, 'areaInHa') : null
        })
      };
    });

    let classViewChartData = _(activeClassesLevelsList)
      .filter((node) => {
        const childrenIds = findFurthestChildrenIds(_.get(node, 'childrenIds'));
        const hasAtLeastOneChildrenActive = childrenIds.some(id => activeClassTreeNodeIds.indexOf(id) >= 0);

        return !hasAtLeastOneChildrenActive;
      })
      .map((node) => {
        const furthestParent = findFurthestParent(node);
        const currentData = _.find(parsedData, (item) => _.get(item, 'classData.id') === node.id && item.year === currentYear);

        return {
          ...currentData,
          furthestParentId: _.get(furthestParent, 'id'),
          furthestParent: furthestParent
        };
      })
      .groupBy('furthestParentId')
      .map((data) => {
        const sampleItem = _.first(data);
        const stringList = _.get(sampleItem, 'furthestParent.i18nStrings');
        const selectedString = _.find(stringList, { language: locale });
        const totalValue = _.sumBy(data, 'areaInHa');

        return {
          name: _.get(selectedString, 'stringValue'),
          value: totalValue,
          itemStyle: {
            color: _.get(sampleItem, 'furthestParent.color')
          },
        }
      })
      .value();

    if (hasCustomCOSValues) {
      classViewChartDataUnit = 'GtCOS';
      let classViewChartDataMinValue = _.get(_.minBy(classViewChartData, 'value'), 'value');

      const classViewChartDataValues = (factor) => {
        return _.map(classViewChartData, (item) => {
          const value = _.get(item, 'value');

          if (value) {
            return {
              ...item,
              value: value * factor
            }
          } else {
            return item;
          }
        });
      };

      if (classViewChartDataMinValue < 0.001) {
        classViewChartData = classViewChartDataValues(1000000);
        classViewChartDataUnit = 'KtCOS';
      } else if (classViewChartDataMinValue < 1) {
        classViewChartData = classViewChartDataValues(1000);
        classViewChartDataUnit = 'MtCOS';
      }
    }


    let historicalSeriesChartData = _.map(parsedClassesLevelsList, (item) => {
      return {
        name: item.name,
        color: item.color,
        data: item.data,
        level: item.level,
        padding: item.level * 12,
      };
    });

    if (hasSingleHistoricalData) {
      let totalData = _.reduce(historicalSeriesChartData, (data, item) => {
        const itemData = _.get(item, 'data');

        _.each(itemData, (value, index) => {
          data[index] = (data[index] || 0) + value;
        });

        return data;
      }, []);

      if (hasCustomCOSValues) {
        historicalSeriesChartDataUnit = 'GtCOS';
        let historicalSeriesChartDataMinValue = _.min(totalData, 'value');

        const historicalSeriesChartDataValues = (factor) => {
          return _.map(totalData, (itemValue) => {
            return itemValue ? (itemValue * factor) : itemValue;
          });
        };

        if (historicalSeriesChartDataMinValue < 0.001) {
          totalData = historicalSeriesChartDataValues(1000000);
          historicalSeriesChartDataUnit = 'KtCOS';
        } else if (historicalSeriesChartDataMinValue < 1) {
          totalData = historicalSeriesChartDataValues(1000);
          historicalSeriesChartDataUnit = 'MtCOS';
        }
      }

      historicalSeriesChartData = [{
        name: 'Total',
        color: '#886956',
        data: totalData,
        level: 1,
        padding: 12,
      }];
    }

    return {
      years,
      classViewChartData,
      classViewChartDataUnit,
      historicalSeriesChartData,
      historicalSeriesChartDataUnit,
    };
  };

  useEffect(() => {
    if (!loadingClassTreeData && !loadingBaseData && baseData) {
      setChartsData(parseChartsData());
    }
  }, [baseData, loadingBaseData, loadingClassTreeData, activeClassTreeNodeIds, activeYear]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const renderClassViewChart = () => {
    const data = _.get(chartsData, 'classViewChartData');
    const classViewChartDataUnit = _.get(chartsData, 'classViewChartDataUnit');

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.title" values={{ year: currentYear }} /></h2>
        <PieChart
          serie={{
            title: classViewChartDataUnit || unit || formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.unit'),
            data
          }}
          mantissa={ valueMantissa }
        />
      </div>
    );
  };

  const renderHistoricalSeriesChart = () => {
    const years = _.get(chartsData, 'years');
    const data = _.get(chartsData, 'historicalSeriesChartData');
    const historicalSeriesChartDataUnit = _.get(chartsData, 'historicalSeriesChartDataUnit');

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.charts.historical_line.title" /></h2>
        <LineChart
          chartType={ chartType }
          categories={ years }
          series={ data }
          unit={ historicalSeriesChartDataUnit || unit }
          mantissa={ valueMantissa }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const classViewChartData = _.get(chartsData, 'classViewChartData');
    const classViewChart = {
      title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.title', { year: currentYear }),
      columns: ['Total'],
      rows: _.map(classViewChartData, (item) => {
        return {
          name: _.get(item, 'name'),
          data: [_.get(item, 'value')],
        };
      }),
      mantissa: valueMantissa
    };

    const years = _.get(chartsData, 'years');
    const historicalSeriesChartData = _.get(chartsData, 'historicalSeriesChartData');
    const historicalSeriesChart = {
      title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.historical_line.title'),
      columns: years,
      rows: historicalSeriesChartData,
      mantissa: valueMantissa
    };

    onShowData([
      classViewChart,
      historicalSeriesChart,
    ]);
  };

  return (
    <Fragment>
      { loadingBaseData &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { renderClassViewChart() }
      { renderHistoricalSeriesChart() }
      <div id="data-view-button" className={ styles.actionWrapper }>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={ handleShowData }
          className={ styles.actionButton }
        >
          <TableChartIcon />
          <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
        </Button>
      </div>
    </Fragment>
  );
}
