import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import numbro from 'numbro';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import LineChart from '../../../MapBiomasLandingPage/views/Map/components/StatisticsBox/components/LineChart';
import PieChart from '../../../MapBiomasLandingPage/views/Map/components/StatisticsBox/components/PieChart';

import useFormatMessage from '../../../../hooks/useFormatMessage';

import styles from '../../TerritoryDashboard.module.scss';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
} from './query';

const formatNumber = (value) => {
  return numbro(value).format({
    thousandSeparated: true,
    mantissa: 0
  });
};

export default function CoveragePieChartContent({
  activeYear,
  classTreeKey = 'default',
  territoryId,
  statisticsDataIsVisible = false,
  onShowData = () => {},
}) {
  const locale = localStorage.getItem('locale') || 'pt-BR';
  const formatMessage = useFormatMessage();

  const intl = useIntl()

  const [chartsData, setChartsData] = useState(null);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    }
  });
  const classTreeId = _.get(classTreeData, 'classTreeByKey[0].id');

  const territoryIds = [territoryId];
  const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  console.log(' CoveragePieChartContent classesLevelsList', activeClassesLevelsListItems);
  const activeClassesLevelsListItems = _.map(_.filter(classesLevelsList, { level: 1 }), 'id');
  console.log('activeClassesLevelsListItems', activeClassesLevelsListItems);
  const { data: baseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      territoryIds,
      classTreeId: classTreeId
    },
    skip: _.isEmpty(territoryIds) || !classTreeId || loadingClassTreeData
  });

  const findFurthestParent = (node) => {
    const { parentId } = node;

    if (parentId) {
      const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
      const parentNode = _.find(classesLevelsList, { id: parentId });

      if (parentNode) {
        return _.get(parentNode, 'parentId') ? findFurthestParent(parentNode) : parentNode;
      }
    } else {
      return node;
    }
  };

  const findFurthestChildrenIds = (childrenIds = []) => {
    if (!_.isEmpty(childrenIds)) {
      const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

      const ids = _.map(childrenIds, (id) => {
        const node = _.find(classesLevelsList, { id });

        return findFurthestChildrenIds(_.get(node, 'childrenIds'));
      });

      return [
        ...childrenIds,
        ...(_.compact(_.flattenDeep(ids)))
      ]
    } else {
      return _.compact(_.flattenDeep(childrenIds));
    }
  };

  const parseChartsData = () => {
    const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
    console.log('classesLevelsList', classesLevelsList);
    const activeClassesLevelsList = _.filter(classesLevelsList, (item) => _.includes(activeClassesLevelsListItems, item.id));
    const coverageClassificationDataList = _.get(baseData, 'coverageClassificationDataList');
    const aggregatedCoverageClassificationDataList = _.values(_.reduce(coverageClassificationDataList, (result, obj) => {
      const key = `${ obj.year }-${ obj.classTreeNodeId }`;
      const nextValue = result[key] ? result[key].areaInHa + obj.areaInHa : obj.areaInHa;

      result[key] = {
        ...obj,
        areaInHa: nextValue
      };

      return result;
    }, {}));

    const parsedData = _.filter(_.map(aggregatedCoverageClassificationDataList, (item) => {
      const classData = _.find(classesLevelsList, { id: item.classTreeNodeId });

      return {
        year: Number(_.replace(item.year, '_', '')),
        areaInHa: item.areaInHa,
        classData,
      };
    }), 'classData');
    const years = _.sortBy(_.uniq(_.map(parsedData, 'year')));

    const parsedClassesLevelsList = _.map(activeClassesLevelsList, (classData) => {
      const stringList = _.get(classData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const className = `${ _.join(_.get(classData, 'positionInTree'), '.') }. ${ _.get(selectedString, 'stringValue') }`;

      return {
        ...classData,
        name: className,
        data: _.map(years, (year) => {
          const coverageClassificationData = _.find(parsedData, (item) => {
            return item.year === year &&
              item.classData.id === classData.id;
          });

          return coverageClassificationData ? _.get(coverageClassificationData, 'areaInHa') : null
        })
      };
    });

    const classViewChartData = _(activeClassesLevelsList)
      .filter((node) => {
        const childrenIds = findFurthestChildrenIds(_.get(node, 'childrenIds'));
        const hasAtLeastOneChildrenActive = childrenIds.some(id => activeClassesLevelsListItems.indexOf(id) >= 0);

        return !hasAtLeastOneChildrenActive;
      })
      .map((node) => {
        const furthestParent = findFurthestParent(node);
        const currentData = _.find(parsedData, (item) => _.get(item, 'classData.id') === node.id && item.year === activeYear);

        return {
          ...currentData,
          furthestParentId: _.get(furthestParent, 'id'),
          furthestParent: furthestParent
        };
      })
      .groupBy('furthestParentId')
      .map((data) => {
        const sampleItem = _.first(data);
        const stringList = _.get(sampleItem, 'furthestParent.i18nStrings');
        const selectedString = _.find(stringList, { language: locale });
        const totalValue = _.sumBy(data, 'areaInHa');

        return {
          name: _.get(selectedString, 'stringValue'),
          value: totalValue,
          itemStyle: {
            color: _.get(sampleItem, 'furthestParent.color')
          },
        }
      })
      .value();

    const historicalSeriesChartData = _.map(parsedClassesLevelsList, (item) => {
      return {
        name: item.name,
        color: item.color,
        data: item.data,
        level: item.level,
        padding: item.level * 12,
      };
    });

    return {
      years,
      classViewChartData,
      historicalSeriesChartData,
    };
  };

  useEffect(() => {
    if (!loadingClassTreeData && !loadingBaseData && baseData) {
      setChartsData(parseChartsData());
    }
  }, [baseData, loadingBaseData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const renderClassViewChart = () => {
    const data = _.get(chartsData, 'classViewChartData');

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.title" values={{ year: activeYear }} /></h2>
        <PieChart
          serie={{
            title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.unit'),
            data
          }}
        />
      </div>
    );
  };

  const renderHistoricalSeriesChart = () => {
    const years = _.get(chartsData, 'years');
    const data = _.get(chartsData, 'historicalSeriesChartData');

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.charts.historical_line.title" /></h2>
        <LineChart
          categories={ years }
          series={ data }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const classViewChartData = _.get(chartsData, 'classViewChartData');
    const classViewChart = {
      title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.title', { year: activeYear }),
      columns: ['Total'],
      rows: _.map(classViewChartData, (item) => {
        return {
          name: _.get(item, 'name'),
          data: [_.get(item, 'value')],
        };
      }),
    };

    const years = _.get(chartsData, 'years');
    const historicalSeriesChartData = _.get(chartsData, 'historicalSeriesChartData');
    const historicalSeriesChart = {
      title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.historical_line.title'),
      columns: years,
      rows: historicalSeriesChartData,
    };

    onShowData([
      classViewChart,
      historicalSeriesChart,
    ]);
  };

  return (
    <Fragment>
      { loadingBaseData &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { renderClassViewChart() }
      {/* { renderHistoricalSeriesChart() } */}
      {/* <div id="data-view-button" className={ styles.actionWrapper }>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={ handleShowData }
          className={ styles.actionButton }
        >
          <TableChartIcon />
          <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
        </Button>
      </div> */}
    </Fragment>
  );
}
