import React from 'react';
import numbro from 'numbro';
import classnames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import InfoTooltip from '../../../../../../../../components/InfoTooltip';

import styles from './MinimalBarData.module.scss';

const useStyles = makeStyles((theme) => ({
  mainBar: {
    backgroundColor: `${ theme.palette.primary.main } !important`
  },
}));

const formatNumber = (value, mantissa = 0) => numbro(value).format({
  thousandSeparated: true,
  mantissa
});

export default function MinimalBarData({
  label,
  tooltip,
  value,
  year,
  percentage,
  color,
}) {
  const classes = useStyles();
  let customMainBarStyles = {};

  if (color) {
    customMainBarStyles = {
      backgroundColor: color
    };
  }

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.bar }>
        <span className={ styles.backgroundBar } />
        <span
          className={ classnames(styles.mainBar, {
            [classes.mainBar]: !color
          }) }
          style={{ width: `${ percentage }%`, ...customMainBarStyles }}
        />
      </div>
      <div className={ styles.info }>
        <span className={ styles.infoLabel }>
          <span>{ label }</span>
          { tooltip && <InfoTooltip text={ tooltip } /> }
        </span>
        <span className={ styles.infoValue }>
          { year && <b>{ year } / </b> }
          <b>{ formatNumber(value) }</b>
          <small>ha</small>
        </span>
      </div>
    </div>
  );
}
