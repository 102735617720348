import React from 'react';

import FormControl from '@material-ui/core/FormControl';

import CustomSelect from '../../../../../../components/CustomSelect';

import styles from './BaseClassTreeNodesControl.module.scss';

export default function BaseClassTreeNodesControl({
  options,
  value,
  onChange,
}) {
  return (
    <>
      <p className={ styles.baseClassInfo }>Selecione uma classe abaixo para visualizar o resultado aplicado em Uso e Cobertura</p>
      <FormControl
        variant="outlined"
        className={ styles.formControl }
      >
        <CustomSelect
          defaultOrder
          disableClearable={ false }
          options={ options }
          value={ value }
          dataKey="activeBaseClassTreeNodeId"
          label="Filtro"
          onChange={ onChange }
          placeholder="Selecione uma classe"
        />
      </FormControl>
    </>
  );
}
