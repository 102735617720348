import { gql } from 'graphql.macro';

export const GET_TERRITORIES_AUTOCOMPLETE = gql`
  query TerritoriesAutocomplete(
    $categoryId: Int!,
    $text: String!,
  ) {
    territoriesAutocomplete(
      categoryId: $categoryId,
      text: $text
    ) {
      id
      i18nStrings {
        language
        stringValue
      }
      territoryGeometry {
        boundingBox
      }
    }
  }
`;
