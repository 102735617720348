import React from 'react'
import _ from 'lodash'
import numbro from 'numbro'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import styles from './StickyTable.module.scss'

const formatNumber = (value, mantissa = 0) => {
  if (_.isNil(value)) { return '-'; }

  return numbro(value).format({
    thousandSeparated: true,
    mantissa
  });
};

export default function StickyTable({
  rows,
  columns,
  mantissa,
}) {
  return (
    <div className={ styles.tableWrapper }>
      <div>
        <span className={ styles.categoriesTitle }>Classes</span>
        <ul className={ styles.tableCategoriesList }>
          { _.map(rows, (row) => (
            <li
              key={ `table-row-${ _.kebabCase(row.name) }-sticky-column` }
              style={{ paddingLeft: _.get(row, 'padding', 12) }}
            >
              { row.name }
            </li>
          )) }
        </ul>
      </div>
      <TableContainer>
        <Table size="small" className={ styles.table }>
          <TableHead>
            <TableRow>
              { _.map(columns, (column) => (
                <TableCell
                  align="right"
                  key={ `table-column-${ _.kebabCase(column) }` }
                  className={ styles.categoryCell }
                >
                  { column }
                </TableCell>
              )) }
            </TableRow>
          </TableHead>
          <TableBody>
            { _.map(rows, (row) => (
              <TableRow key={ `table-row-${ _.kebabCase(row.name) }` }>
                { _.map(columns, (column, index) => (
                  <TableCell
                    key={ `table-row-${ row.name }-column-${ column }` }
                    align="right"
                  >
                    { formatNumber(row.data[column] || row.data[index], mantissa) }
                  </TableCell>
                )) }
              </TableRow>
            )) }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
