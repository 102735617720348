import React from 'react';

export default function FireHotsitePage() {
  return (
    <iframe
      width={ window.innerWidth }
      height={ window.innerHeight }
      frameBorder="0"
      marginHeight="0"
      marginWidth="0"
      title="MapBiomas Hotsite Fogo"
      src="https://mapbiomasfogocol3v1.netlify.app/"
    />
  );
}
