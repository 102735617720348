import { gql } from 'graphql.macro';

export const GET_POINT_DATA = gql`
  query GetPointData($lat: Float!, $lng: Float!) {
    territoryCategoriesList {
      id
      key
      parentId
      i18nStrings {
        language
        stringValue
      }
    }

    territoriesOnList(lat: $lat, lng: $lng) {
      id
      categoryId
      i18nStrings {
        language
        stringValue
      }
    }
  }
`;
