import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  {
    app @client {
      activeBaseMap
      activeLayers
      layersOpacity
      activeModule
      activeModuleContent
      activeYear
      mapPosition
      showBeforeAndAfterMosaic
      isLoadingBaseData
      notifyUserNewTermOfUseVersion
      timelineLimitsRange
      baseParams {
        groupType
        degradationType
        degradationFragmentationIsolationValue
        degradationCrossingValues {
          degradation_fragmentation_edge_area
          degradation_fragmentation_fragment_size
          degradation_fragmentation_isolation_medium_size
          degradation_fragmentation_isolation_distance
          degradation_fragmentation_isolation_big_size
          degradation_fire_frequency
          degradation_fire_time
          degradation_secondary_vegetation_age
          parsedParams
        }
        territoryType
        territorySubtype
        territoryValueType
        crossingEnabled
        territory
        territories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
        crossing
        classesViewMode
        activeClassTreeOptionValue
        activeClassTreeNodeIds
        activeObjectTreeNodeIds
        activeSubmodule
        activeClassesLevelsListItems
        activeTransitionsClassesListItems
        activePastureQualityQualityClassesListItems
        yearRange
        buffer
      }
    }
  }
`;
