import React from 'react';
import numbro from 'numbro';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import InfoTooltip from '../../../../../../../../components/InfoTooltip';

import MinimalBarData from '../MinimalBarData';

import styles from '../../StatisticsBox.module.scss';

const formatNumber = (value) => {
  if (!value) { return '-'; }

  return numbro(value).format({
    thousandSeparated: true,
    mantissa: 0
  });
};

export default function BaseValuesChart({
  title = 'Área queimada no período',
  total,
  totalTitle,
  totalTitleTooltipKey,
  maxValue,
  maxValueTooltipKey,
  maxYear,
  averageValue,
  averageValueTooltipKey,
  averagePercentage,
  minValue,
  minValueTooltipKey,
  minYear,
  minPercentage,
  color,
}) {
  const formatMessage = useFormatMessage();

  return (
    <div className={ styles.section }>
      <h2 className={ styles.secondaryTitle }>{ title }</h2>
      <ul className={ styles.infoList }>
        <li className={ styles.infoListItem }>
          <span className={ styles.infoListItemValue }>
            <b>{ formatNumber(total) }</b>
            <small>ha</small>
          </span>
          { totalTitle &&
            <span className={ styles.infoListItemLabel }>
              <span>{ totalTitle }</span>
              { totalTitleTooltipKey &&
                <InfoTooltip
                  text={ formatMessage(totalTitleTooltipKey) }
                />
              }
            </span>
          }
        </li>
      </ul>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <MinimalBarData
            label={ formatMessage('mapbiomas.statistics.summary.max.title') }
            tooltip={ formatMessage(maxValueTooltipKey || 'mapbiomas.statistics.summary.max.tooltip') }
            value={ maxValue }
            percentage={ maxValue ? 100 : 0 }
            color={ color }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <MinimalBarData
            label={ formatMessage('mapbiomas.statistics.summary.average.title') }
            tooltip={ formatMessage(averageValueTooltipKey || 'mapbiomas.statistics.summary.average.tooltip') }
            value={ averageValue }
            percentage={ !_.isNaN(averagePercentage) ? averagePercentage : 0 }
            color={ color }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <MinimalBarData
            label={ formatMessage('mapbiomas.statistics.summary.min.title') }
            tooltip={ formatMessage(minValueTooltipKey || 'mapbiomas.statistics.summary.min.tooltip') }
            value={ minValue }
            percentage={ !_.isNaN(minPercentage) ? minPercentage : 0 }
            color={ color }
          />
        </Grid>
      </Grid>
    </div>
  );
}
