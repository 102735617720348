import React from 'react';
import _ from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import styles from './ActiveOptionsList.module.scss';

export default function ActiveOptionsList({
  categoryKey,
  options,
  customIdParam = () => {},
  onClear = () => {},
}) {
  if (_.isEmpty(options)) {
    return null;
  }

  return (
    <ul className={ styles.list }>
      { _.map(options, (option) => {
        const categoryText = categoryKey ? `${ _.get(option, categoryKey) } - ` : '';

        return (
          <li key={ `territory-item-${ option.id }` } className={ styles.listItem }>
            <span className={ styles.listItemText }>{ categoryText }{ option.label }</span>
            <IconButton
              className={ styles.listItemRemoveButton }
              onClick={ onClear.bind(this, customIdParam ? customIdParam(option) : option.id) }
            >
              <ClearIcon />
            </IconButton>
          </li>
        );
      }) }
    </ul>
  );
}
