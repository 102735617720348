import { gql } from 'graphql.macro';

export const GET_CLASS_TREE_DATA = gql`
  query GetClassTreeData(
    $classTreeKey: String!,
  ) {
    classTreeByKey(key: $classTreeKey) {
      id
      key
      i18nStrings {
        language
        stringValue
      }
      mvClassTreeLevelsUi {
        id
        color
        classId
        childrenIds
        positionInTree
        level
        parentId
        defaultName
        classTreeId
        pixelValue
        i18nStrings {
          language
          stringValue
        }
        classTreeNode {
          classe {
            classesTooltips {
              id
            }
          }
        }
      }
    }
  }
`;
