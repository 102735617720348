import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import numbro from 'numbro';
import { useQuery } from '@apollo/react-hooks';

import { useIntl, FormattedMessage } from 'react-intl';

import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import BarChart from '../../components/BarChart';

import customClient from '../../../../../../../../core/custom-apollo-client/custom-apollo-client';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
} from './query';

const formatNumber = (value) => {
  return numbro(value).format({
    thousandSeparated: true,
    mantissa: 0
  });
};

export default function FireAccumulatedStatisticsContent({
  clientType,
  activeYear,
  baseParams,
  classTreeKey,
  localeKey = 'burned',
  statisticsDataIsVisible,
  onShowData,
}) {
  const theme = useTheme();

  const intl = useIntl()

  const fromYear = _.first(activeYear) || 1985;
  const toYear = _.isArray(activeYear) ? _.last(activeYear) : activeYear;
  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');
  const territories = _.get(baseParams, 'territories');
  const territoryIds = _.map(territories, 'id');

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
    client: clientType === 'custom' ? customClient : undefined
  });
  const classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  const filteredActiveClassTreeNodeIds = _.filter(activeClassTreeNodeIds, (nodeId) => {
    const nodeData = _.find(classTreeLevelsList, { id: nodeId });
    const childrenIds = _.get(nodeData, 'childrenIds');

    if (_.isEmpty(childrenIds)) {
      return true;
    } else {
      return !childrenIds.some(id => activeClassTreeNodeIds.indexOf(id) >= 0);
    }
  });

  const { data: baseData, loading, error: errorBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      classTreeNodeIds: filteredActiveClassTreeNodeIds,
      fromYear,
      toYear,
      territoriesIds: territoryIds
    },
    skip: loadingClassTreeData,
    client: clientType === 'custom' ? customClient : undefined
  });

  const getAccumulatedAreaFromAllPeriodData = () => {
    const data = _.sortBy(_.get(baseData, 'frequencyAccumulatedGrouped'), 'year');
    const categories = _.map(data, 'year') || [];
    const values = _.map(data, 'areaInHa') || [];

    return {
      title: intl.formatMessage({id: 'mapbiomas.statistics.burned.accumulated.title'}),
      columns: categories,
      rows: [
        {
          name: 'Total',
          data: values,
        }
      ]
    };
  };

  useEffect(() => {
    const sampleData = _.get(baseData, 'frequencyAccumulatedGrouped');

    if ((!loading && errorBaseData) || _.isEmpty(sampleData)) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }

    if (!loadingClassTreeData && !loading && baseData) {
      setChartsData({
        'accumulatedAreaFromAllPeriod': getAccumulatedAreaFromAllPeriodData(),
      });
    }
  }, [baseData, loading, loadingClassTreeData, errorBaseData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const totalSummary = () => {
    const values = _.get(chartsData, 'accumulatedAreaFromAllPeriod.rows[0].data') || [];
    const lastValue = _.last(values);

    return (
      <div className={ styles.sectionSmall }>
        <h2 className={ styles.secondaryTitle }>
          <span><FormattedMessage id={ `mapbiomas.statistics.${ localeKey }.accumulated.summary.title` } /></span>
        </h2>
        <ul className={ styles.infoList }>
          <li className={ styles.infoListItem }>
            <span className={ styles.infoListItemValue }>
              <b>{ formatNumber(lastValue) }</b>
              <small>ha</small>
            </span>
            <span className={ styles.infoListItemLabel }>
              <span><FormattedMessage id={ `mapbiomas.statistics.${ localeKey }.accumulated.summary.total.title` } /></span>
            </span>
          </li>
        </ul>
      </div>
    );
  };

  const accumulatedAreaFromAllPeriod = () => {
    const categories = _.get(chartsData, 'accumulatedAreaFromAllPeriod.columns') || [];
    const values = _.get(chartsData, 'accumulatedAreaFromAllPeriod.rows[0].data') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }>
          <span>{intl.formatMessage({id: 'mapbiomas.statistics.burned.accumulated.title'})}</span>
        </h2>
        <BarChart
          categories={ categories }
          series={ [
            {
              name: 'Total',
              color: _.get(theme, 'palette.primary.main'),
              data: values,
            }
          ] }
        />
      </div>
    );
  };

  const handleShowData = () => {
    onShowData([
      _.get(chartsData, 'accumulatedAreaFromAllPeriod')
    ]);
  };

  const renderData = () => {
    if (emptyData) {
      return null;
    }

    return (
      <Fragment>
        { totalSummary() }
        { accumulatedAreaFromAllPeriod() }
        <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p><FormattedMessage id="mapbiomas.statistics.processing_data" /></p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
