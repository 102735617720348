import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import styles from './TabList.module.scss';

const useStyles = makeStyles((theme) => ({
  item: {
    '&:hover': {
      color: `${ theme.palette.primary.main } !important`,
    }
  },
  activeItem: {
    color: `${ theme.palette.primary.main } !important`,
    boxShadow: '0 1px 2px rgba(180, 180, 180, 0.12), 0 1px 1px rgba(200, 200, 200, 0.24)',
    backgroundColor: 'white !important',
    '&:hover': {
      color: `${ theme.palette.primary.main } !important`,
    }
  },
}));

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: '8px 12px',
    fontSize: 14,
    color: 'white',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
  },
}))(Tooltip);

export default function TabList({
  size = 'default',
  hasCustomItemSize = false,
  fullWidth = false,
  itemSizes = { xs: 6 },
  value,
  items = [],
  onChange = () => {}
}) {
  const classes = useStyles();
  let customItemSizes = _.clone(itemSizes);

  return (
    <Grid container spacing={ 1 } className={ classnames(styles.navList, {
      [styles.navListSmall]: size === 'small',
      [styles.navListFullWidth]: fullWidth,
    }) }>
      { _.map(items, (item, index) => {
        const { tooltip } = item;

        if (hasCustomItemSize && _.size(items) === 3 && index === 2) {
          customItemSizes = { xs: 12 };
        }

        if (tooltip) {
          return (
            <Grid
              item
              { ...customItemSizes }
              key={ `tab-item-${ item.id }` }
            >
              <CustomTooltip title={ tooltip }>
                <li
                  className={ classnames(styles.navListItem, classes.item, {
                    [classes.activeItem]: item.id === value,
                    [styles.disabledItem]: item.disabled,
                  }) }
                  id={ item.elementId }
                  onClick={ onChange.bind(this, item.id) }
                >
                  { item.label }
                </li>
              </CustomTooltip>
            </Grid>
          );
        } else {
          return (
            <Grid
              item
              { ...customItemSizes }
              key={ `tab-item-${ item.id }` }
            >
              <li
                className={ classnames(styles.navListItem, classes.item, {
                  [classes.activeItem]: item.id === value,
                  [styles.disabledItem]: item.disabled,
                }) }
                id={item.elementId}
                onClick={ onChange.bind(this, item.id) }
              >
                { item.label }
              </li>
            </Grid>
          );
        }
      }) }
    </Grid>
  );
}
