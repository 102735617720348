import React, { useState } from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Collapse from '@material-ui/core/Collapse'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { updateStateCache } from '../../../../../../utils/apollo'
import { onCurrentLocaleCustom } from '../../../../../../utils/locale'

import { GET_COMPONENT_INITIALIZATION_DATA } from './query'

import styles from './GeospatialObjects.module.scss'

const useStyles = makeStyles((theme) => ({
  link: {
    color: `#16a085 !important`,
  },
}))

const MAP_POSITION_IN_TREE_TO_DOWNLOAD_URL = {
'1.1.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_public_airfield.zip',
'1.1.2': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_private_airfield.zip',
'1.1.3': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_helipad.zip',  
'1.2.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_lock.zip',
'1.2.2': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_transshipment_station.zip',
'1.2.3': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_waterway.zip',
'1.2.4': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_port_facility_for_tourism.zip',
'1.2.5': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_registered_port_facility.zip',
'1.2.6': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_organized_port.zip',
'1.2.7': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_private_use_terminal.zip',
'1.2.8': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_other_port_facilities.zip',
'1.3.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_aqueduct.zip',
'1.3.2.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_transportation_gas_pipeline.zip',
'1.3.2.2': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_distribution_gas_pipeline.zip',
'1.3.2.3': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_outflow_gas_pipeline.zip',  
'1.3.3': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_mining_pipeline.zip',
'1.3.4': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_oil_pipeline.zip',
'1.3.5': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_polyduct.zip',
'1.3.6': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_pipeline_not_identified.zip',
'1.4':  'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_railroad.zip',
'1.5.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_state_highway.zip',
'1.5.2': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_federal_highway.zip',
'1.5.3': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_other_road_sections.zip',
'2.1.1.1.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_oil_refineries_and_other_processing_facilities.zip', 
'2.1.1.1.2': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_oil_and_oil_products_storage_and_distribution_terminal.zip', 
'2.1.1.2.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_compression_station.zip', 
'2.1.1.2.2': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_gas_delivery_point.zip', 
'2.1.1.2.3': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_natural_gas_processing_unit.zip', 
'2.1.1.2.4': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_liquefied_natural_gas_terminals.zip',
'2.1.2.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_biodiesel_plant.zip',
'2.1.2.2': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_biogas_plant.zip',
'2.1.2.3': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_ethanol_plant.zip',
'2.1.3.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_liquefied_petroleum_gas_base.zip',
'2.1.3.2': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_liquid_fuel_base.zip',
'2.2.1.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_substation.zip',
'2.2.1.2': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_transmission_line.zip',
'2.2.2.1.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_wind_power_plant.zip',
'2.2.2.1.2': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_photovoltaic_solar_power_plant.zip',
'2.2.2.1.3': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_biomass_thermal_power_plant.zip',
'2.2.2.1.4': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_hydroelectric_power_plant.zip',
'2.2.2.1.5': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_small_hydropower_plant.zip',
'2.2.2.1.6': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_hydroelectric_generating_centers.zip',
'2.2.2.2.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_fossil_fuel_thermal_power_plant.zip',
'2.2.2.2.2': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_thermonuclear_power_plant.zip',
'3.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_energetic_product_mine.zip',   
'3.2': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_metallic_product_mine.zip',     
'3.3': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_other_products_mine.zip',  
'3.4': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_mining_dam.zip',
'4.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_warehouses.zip',    
'4.2': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_slaughterhouse.zip', 
'5.1': 'https://storage.googleapis.com/mapbiomas-public/initiatives/brasil/collection_8/downloads/infrastructure/structure_base_radio_station.zip' 
};

export default function GeospatialObjects({
  objectTree = []
}) {
  const classes = useStyles()
  const apolloClient = useApolloClient()
  const { data, loading } = useQuery(GET_COMPONENT_INITIALIZATION_DATA)
  if (loading || !data) {
    return null
  }

  const appState = data.app
  const updateState = _.partial(updateStateCache, apolloClient)

  const [expandedNodeIds, setExpandedNodeIds] = useState([])
  const nodes = _.get(objectTree, 'geospatialObjectCategoryTreeLevels')

  const toggleTreeNode = (node) => {
    if (_.includes(expandedNodeIds, node.id)) {
      setExpandedNodeIds(_.without(expandedNodeIds, node.id))
    } else {
      setExpandedNodeIds([ ...expandedNodeIds, node.id ])
    }
  }

  const handleObjectTreeNodeToggle = (objectTreeNode) => {
    const nodeId = _.get(objectTreeNode, 'id')

    if (_.includes(appState.baseParams.activeObjectTreeNodeIds, nodeId)) {
      updateState('app.baseParams', [
        'activeObjectTreeNodeIds',
        _.without(appState.baseParams.activeObjectTreeNodeIds, nodeId)
      ])
    } else {
      updateState('app.baseParams', [
        'activeObjectTreeNodeIds',
        [ ...appState.baseParams.activeObjectTreeNodeIds, nodeId ]
      ])
    }
  }

  const renderExpandIcon = (node) => {
    const expanded = _.includes(expandedNodeIds, node.id)
    const icon = expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />

    if (!_.isEmpty(_.get(node, 'childrenIds'))) {
      return (
        <IconButton
          className={ styles.classListItemContentControl }
          onClick={ _.partial(toggleTreeNode, node) }
        >
          { icon }
        </IconButton>
      )
    }
  }

  const renderCheckbox = (node) => {
    const toggled = _.includes(appState.baseParams.activeObjectTreeNodeIds, node.id)

    return (
      <IconButton
        className={ styles.classListItemCheckControl }
        style={{ color: node.color }}
        onClick={ _.partial(handleObjectTreeNodeToggle, node) }
      >
        { toggled ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> }
      </IconButton>
    )
  }

  const renderClassTreeBranch = ({ parentId = null }) => {
    const branchNodes = _.filter(nodes, { parentId })

    return (
      <ul
        className={ classnames(styles.classNode, {
          [styles.classChildNode]: !_.isNil(parentId)
        }) }
      >
        { _.map(_.sortBy(branchNodes, _.flow(_.property('positionInTree'), _.last)), (node) => {
          const prettyIndexOfNodeInTree = _.join(node.positionInTree, '.');
          const downloadUrl = MAP_POSITION_IN_TREE_TO_DOWNLOAD_URL[prettyIndexOfNodeInTree];

          return (
            <li
              key={ `class-item-level-${ node.id }` }
              className={ styles.classListItemBase }
            >
              <div className={ styles.classListItemHeader }>
                { _.isEmpty(node.childrenIds) && renderCheckbox(node) }
                <span
                  style={{ fontSize: 12 }}
                  className={ styles.classListItemTitle }
                >
                  <span>{ prettyIndexOfNodeInTree }. { onCurrentLocaleCustom(node.i18nStrings) }</span>
                  { downloadUrl &&
                    <IconButton
                      className={ styles.actionBoxControlButton }
                      href={ downloadUrl }
                      target="_blank"
                    >
                      <CloudDownloadIcon />
                    </IconButton>
                  }
                </span>
                { renderExpandIcon(node) }
              </div>
              <Collapse in={ _.includes(expandedNodeIds, node.id) }>
                <div>
                  { renderClassTreeBranch({ parentId: node.id }) }
                </div>
              </Collapse>
            </li>
          )
        }) }
      </ul>
    )
  }

  return (
    <div className={ styles.wrapper }>
      <h2 className={ styles.title }>
        Categorias
      </h2>
      <p className={ styles.descriptionText }><FormattedMessage id="mapbiomas.header.infrastructure.description.click" /> <a href="https://mapbiomas-br-site.s3.amazonaws.com/Gloss%C3%A1rio_-_Camadas_de_Infraestrutura_2020_-_PORT___ENG.xlsx" target="_blank" className={ classes.link }><FormattedMessage id="mapbiomas.header.infrastructure.description.here" /></a> <FormattedMessage id="mapbiomas.header.infrastructure.description.content" /></p>
      { renderClassTreeBranch({ parentId: null }) }
    </div>
  )
}
