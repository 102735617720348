import React from 'react';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import numbro from 'numbro';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import positionTooltipInsideDocument from '../../../../../../../../helpers/positionTooltipInsideDocument';

function tooltipFormatter(unit, mantissa = 0, params) {
  const colorSpan = (color) => `<br /><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${ color }"></span>`;
  let str = `<span>${ params[0].axisValue }</span>`;

  params.forEach((item) => {
    const value = _.isObject(item.data) ? item.data.value : item.data;

    if (value) {
      const valueLabel = numbro(value).format({
        thousandSeparated: true,
        mantissa
      });
      const paramValue = `<span>${ colorSpan(item.color) } ${ item.seriesName }: ${ valueLabel }${ unit ? ` ${ unit }` : '' }</span>`;

      str += paramValue;
    }
  });

  return str;
}

const yAxisMillionLabelFormatter = (value) => {
  return numbro(value / 1000000).format({
    thousandSeparated: true,
    mantissa: 1
  });
};

const yAxisLabelPercentageFormatter = (value) => {
  const parsedValue = numbro(value).format({
    thousandSeparated: true,
    mantissa: 0
  });

  return `${ parsedValue }%`;
};

export default function BarChart({
  height = 200,
  isPercentage,
  categories = [],
  series = [],
  vertical = true,
  hideXAxisLabel = false,
  hasAvgMarkLine = false,
  unit,
  mantissa = 0,
}) {
  const formatMessage = useFormatMessage();
  const maxValue = _(series)
    .map(({ data }) => {
      const sampleItem = _.first(data);

      if (_.get(sampleItem, 'value')) {
        return _.map(data, 'value');
      } else {
        return data;
      }
    })
    .flatten()
    .max();
  const hasMillionValue = maxValue > 1000000;

  let yAxisName = unit || 'Hectares';

  if (hasMillionValue) {
    yAxisName = unit || formatMessage('mapbiomas.statistics.million_hectares');
  }

  let nameGap = 55;
  let gridLeft = '30px';
  let markLine = null;

  if (hasMillionValue) {
    nameGap = 35;
    gridLeft = '30px';
  } else if (isPercentage) {
    nameGap = 15;
    gridLeft = '15px';
  }

  let xAxis = {
    type: "category",
    axisPointer: {
      type: "shadow"
    },
    axisLabel: {
      show: !hideXAxisLabel,
      fontSize: 11,
      color: "#767676"
    },
    axisLine: {
      color: "#DDD"
    },
    data: categories
  };
  let yAxis = {
    name: !isPercentage ? yAxisName : null,
    nameLocation: 'middle',
    nameRotate: 90,
    nameGap,
    nameTextStyle: {
      fontSize: 11,
      color: "#767676"
    },
    max: isPercentage ? 100 : undefined,
    axisTick: {
      show: false
    },
    axisLine: {
      color: "#DDD"
    },
    splitLine: {
      lineStyle: {
        color: "rgba(0, 0, 0, 0.05)"
      }
    },
    axisLabel: {
      formatter: (value) => {
        if (isPercentage) {
          return yAxisLabelPercentageFormatter(value);
        } else if (hasMillionValue) {
          return yAxisMillionLabelFormatter(value);
        } else {
          const valueLabel = numbro(value).format({
            thousandSeparated: true,
            mantissa: 0
          });

          return valueLabel;
        }
      },
      fontSize: 11,
      color: "#767676"
    }
  };

  if (!vertical) {
    let tempValue = xAxis;
    xAxis = {
      ...yAxis,
      nameRotate: undefined,
      nameGap: 25,
      axisLabel: {
        show: false
      },
      axisTick: {
        show: false
      },
    };
    yAxis = tempValue;
  }

  if (hasAvgMarkLine) {
    markLine = {
      symbol: [null, null],
      lineStyle: {
        type: 'solid'
      },
      label: {
        fontSize: 11,
        color: '#767676',
        formatter: (params) => {
          const { value } = params;

          if (isPercentage) {
            return yAxisLabelPercentageFormatter(value);
          } else if (hasMillionValue) {
            return yAxisMillionLabelFormatter(value);
          } else {
            const valueLabel = numbro(value).format({
              thousandSeparated: true,
              mantissa: 0
            });

            return valueLabel;
          }
        }
      },
      data: [{ type: 'average', name: 'Média' }]
    };
  }

  return (
    <ReactEcharts
      style={ { height } }
      option={ {
        xAxis,
        yAxis,
        toolbox: {
          feature: {
            saveAsImage: {
              name: 'MapBiomas',
              title: 'Download'
            }
          }
        },
        tooltip: {
          position: positionTooltipInsideDocument,
          appendToBody: true,
          // confine: true,
          // position: [10, 10],
          // position: 'right',
          // position: function (point, params, dom, rect, size) {
          //   console.log('point', point);
          //   // fixed at top
          //   return [0, 0];
          //   // return {
          //   //   right: point[0],
          //   //   top: -10
          //   // };
          // },
          textStyle: {
            align: "left",
            color: "#444",
            fontSize: 13
          },
          backgroundColor: "#FFFFFF",
          borderColor: "rgba(0, 0, 0, 0.1)",
          borderWidth: 1,
          extraCssText: "box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);",
          trigger: "axis",
          formatter: tooltipFormatter.bind(this, unit, mantissa),
        },
        grid: {
          top: '16px',
          right: !hasAvgMarkLine ? '10px' : '20px',
          bottom: '16px',
          left: gridLeft,
          containLabel: true
        },
        series: _.map(series, (serie) => {
          return {
            ...serie,
            type: 'bar',
            smooth: true,
            markLine
          };
        }),
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       name: 'MapBiomas',
        //       title: 'Download',
        //     }
        //   }
        // },
      } }
      notMerge={ true }
    />
  );
}
