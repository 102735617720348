import React, { useRef, useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import html2canvas from 'html2canvas';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import ImageIcon from '@material-ui/icons/Image';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import SankeyChart from '../../../../components/SankeyChart';

import useFormatMessage from '../../../../../../../../../../hooks/useFormatMessage';
import useWindowSize from '../../../../../../../../../../hooks/useWindowSize';

import styles from './SankeyChartControl.module.scss';

const DEFAULT_PADDING = 340;
const CUSTOM_PADDING = 480;

const exportAsImage = async (element, imageFileName) => {
  const canvas = await html2canvas(element);
  const image = canvas.toDataURL('image/png', 1.0);
  downloadImage(image, imageFileName);
};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement('a');
  fakeLink.style = 'display:none;';
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

export default function SankeyChartControl({
  activeLevel,
  fromYear,
  toYear,
  nodes,
  links,
  onClick,
}) {
  const chartRef = useRef();
  const windowSize = useWindowSize();
  const formatMessage = useFormatMessage();
  const [activeLinks, setActiveLinks] = useState([]);
  const [activeSourceClasses, setActiveSourceClasses] = useState([]);
  const [activeTargetClasses, setActiveTargetClasses] = useState([]);
  const hasClassesControl = activeLevel !== 1;
  const PADDING = !hasClassesControl ? DEFAULT_PADDING : CUSTOM_PADDING;

  useEffect(() => {
    if (hasClassesControl) {
      setActiveLinks([]);
    } else {
      setActiveLinks(links);
    }
    setActiveSourceClasses([]);
    setActiveTargetClasses([]);
  }, [activeLevel]);

  useEffect(() => {
    if (hasClassesControl) {
      const validSourceIds = _.map(activeSourceClasses, 'id');
      const validTargetIds = _.map(activeTargetClasses, 'id');
      let nextLinks = _.cloneDeep(links);
      nextLinks = _.filter(nextLinks, ({ source, target }) => {
        if (!_.isEmpty(validSourceIds) && _.isEmpty(validTargetIds)) {
          return _.includes(validSourceIds, source);
        } else if (_.isEmpty(validSourceIds) && !_.isEmpty(validTargetIds)) {
          return _.includes(validTargetIds, target);
        } else {
          return _.includes(validSourceIds, source) && _.includes(validTargetIds, target);
        }
      });

      setActiveLinks(nextLinks);
    } else {
      setActiveLinks(links);
    }
  }, [activeSourceClasses, activeTargetClasses, links]);

  const originOptions = _(links)
    .map('source')
    .uniq()
    .map((nodeId) => _.find(nodes, { id: nodeId }))
    .filter(({ level }) => level === activeLevel)
    .value();
  const destinationOptions = _(links)
    .map('target')
    .uniq()
    .map((nodeId) => _.find(nodes, { id: nodeId }))
    .filter(({ level }) => level === activeLevel)
    .value();

  const handleAutocompleteChange = (type, _event, newValue) => {
    if (type === 'source') {
      setActiveSourceClasses(newValue);
    } else if (type === 'target') {
      setActiveTargetClasses(newValue);
    }
  };

  const handleDownload = () => {
    exportAsImage(chartRef.current, 'MapBiomas-Sankey');
  };

  const renderClassesAutocomplete = (type = 'source') => {
    let options = originOptions;
    let value = activeSourceClasses;

    if (type === 'target') {
      options = destinationOptions;
      value = activeTargetClasses;
    }

    return (
      <FormControl
        variant="outlined"
        className={ styles.autocompleteFormControl }
      >
        <Autocomplete
          multiple
          options={ options }
          getOptionLabel={ (option) => option.label }
          value={ value }
          onChange={ handleAutocompleteChange.bind(this, type) }
          renderInput={ (params) => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              label={ formatMessage(`mapbiomas.statistics.sankey.${ type }_classes`) }
              placeholder={ formatMessage('mapbiomas.statistics.sankey.classes_select_placeholder') }
            />
          ) }
        />
      </FormControl>
    );
  };

  const filteredNodes = _(activeLinks)
    .map(({ source, target }) => ([source, target]))
    .flatten()
    .uniq()
    .map((id) => _.find(nodes, { id }))
    .compact()
    .value();
  const nodeIds = _.map(filteredNodes, 'id');
  const filteredLinks = _.filter(activeLinks, ({ source, target }) => _.includes(nodeIds, source) && _.includes(nodeIds, target));

  return (
    <div className={ styles.contentWrapper }>
      { hasClassesControl &&
        <Fragment>
          { renderClassesAutocomplete('source') }
          { renderClassesAutocomplete('target') }
        </Fragment>
      }
      <p className={ styles.sankeyYears }>{ fromYear } - { toYear }</p>
      <Tooltip title="Download PNG">
        <Button
          variant="contained"
          size="small"
          className={ styles.downloadButton }
          onClick={ handleDownload }
        >
          <ImageIcon />
        </Button>
      </Tooltip>
      { !_.isEmpty(activeLinks) && !_.isEmpty(filteredNodes) &&
        <div className={ styles.sankeyWrapper } style={ { height: windowSize.height - PADDING } }>
          <div style={ { height: !hasClassesControl ? 720 : 1200 } } ref={ chartRef }>
            <SankeyChart
              nodes={ filteredNodes }
              links={ filteredLinks }
              onClick={ onClick }
            />
          </div>
        </div>
      }
    </div>
  );
}
