import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import BarChart from '../../components/BarChart';
import PieChart from '../../components/PieChart';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
} from './query';

const CLASS_NAME_SUMMARY_MAPPER = {
  '0': ['Nenhuma', 'Zero'],
  '1': ['Uma', 'One'],
  '2': ['Duas', 'Two'],
  '3': ['Três', 'Three'],
  '4': ['Quatro', 'Four'],
  '5': ['Cinco', 'Five'],
  '> 5': ['Mais de Cinco', 'More Than Five'],
  '> 6': ['Mais de seis', 'Six or more'],
};

export default function NumberOfClassesStatisticsContent({
  activeYear,
  baseParams,
  classTreeKey,
  statisticsDataIsVisible,
  onShowData,
  orderByLabelName = false,
  localeKey = 'number_of_classes_content',
}) {
  const locale = localStorage.getItem('locale') || 'pt-BR';

  const yearFormatted = Number(_.join(activeYear, ''));
  const years = [yearFormatted];
  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');
  const territories = _.get(baseParams, 'territories');
  const territoryIds = _.map(territories, 'id');

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    }
  });
  const classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  const { data: baseData, loading, error: errorBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      classTreeNodeIds: activeClassTreeNodeIds,
      years: years,
      territoriesIds: territoryIds
    },
    skip: loadingClassTreeData
  });

  const isLoading = loadingClassTreeData || loading;

  const getTotalAreaByClassesData = () => {
    const data = _.get(baseData, 'getCalcAcumCoverageByClasses');

    let parsedSeries = _.map(_.get(_.first(data), 'acumYear'), (item) => {
      const { classTreeNodeId, total } = item;

      const nodeData = _.find(classTreeLevelsList, { id: classTreeNodeId });

      if (!nodeData) {
        return null;
      }

      const stringList = _.get(nodeData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const label = _.get(selectedString, 'stringValue');
      const prettyIndexOfNodeInTree = _.join(_.get(nodeData, 'positionInTree'), '.');

      return {
        nodeId: classTreeNodeId,
        name: `${ prettyIndexOfNodeInTree }. ${ label }`,
        position: orderByLabelName ? Number(label.match(/\d/g).join('')) : null,
        color: _.get(nodeData, 'color'),
        total
      };
    });

    parsedSeries = _.map(_.compact(parsedSeries), (serie) => {
      let name = serie.name;

      _.each(CLASS_NAME_SUMMARY_MAPPER, (values, key) => {
        _.each(values, (value) => {
          if (_.includes(_.kebabCase(name), _.kebabCase(value))) {
            name = key;
          }
        })
      });

      return { ...serie, name };
    });

    const sortedSeries = _.sortBy(parsedSeries, orderByLabelName ? 'position' : 'name');

    return {
      series: sortedSeries,
    };
  };

  useEffect(() => {
    if (!isLoading && errorBaseData) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }

    if (!isLoading && baseData) {
      setChartsData({
        'totalAreaByClasses': getTotalAreaByClassesData(),
      });
    }
  }, [baseData, isLoading, errorBaseData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const numberOfClasses = () => {
    const series = _.get(chartsData, 'totalAreaByClasses.series') || [];

    const parsedSeries = _.map(series, (serie) => {
      return {
        name: serie.name,
        value: serie.total,
        itemStyle: {
          color: serie.color
        },
      }
    });

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id={ `mapbiomas.${ localeKey }.pie_chart_title` } /></h2>
        <PieChart
          serie={{
            title: 'Total',
            data: parsedSeries
          }}
        />
      </div>
    );
  };

  const areaByNumberOfClasses = () => {
    const series = _.get(chartsData, 'totalAreaByClasses.series') || [];

    const parsedSeries = _.map(series, (serie) => {
      return {
        name: serie.name,
        value: serie.total,
        itemStyle: {
          color: serie.color
        },
      }
    });

    const hideXAxisLabel = _.size(_.get(_.sample(series), 'name')) > 5;

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id={ `mapbiomas.${ localeKey }.bar_chart_title` } /></h2>
        <BarChart
          hideXAxisLabel={ hideXAxisLabel }
          categories={ _.map(series, 'name') }
          series={ [
            {
              name: 'Total',
              data: parsedSeries
            }
          ] }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const series = _.get(chartsData, 'totalAreaByClasses.series') || [];
    const parsedRows = _.map(series, (item) => {
      return {
        ...item,
        data: [_.get(item, 'total')]
      };
    });

    const numberOfClassesChart = {
      title: <FormattedMessage id={ `mapbiomas.${ localeKey }.bar_chart_title` } />,
      columns: ['Total'],
      rows: parsedRows
    };

    onShowData([
      numberOfClassesChart,
    ]);
  };

  const renderData = () => {
    if (emptyData) {
      return null;
    }

    return (
      <Fragment>
        { numberOfClasses() }
        { areaByNumberOfClasses() }
        <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      { isLoading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p>Nenhum resultado encontrado.</p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
