import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import { useIntl, FormattedMessage } from 'react-intl';

import BarChart from '../../components/BarChart';

import customClient from '../../../../../../../../core/custom-apollo-client/custom-apollo-client';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
} from './query';

export default function FrequencyByYearStatisticsContent({
  clientType,
  ageMode = false,
  activeYear,
  baseParams,
  classTreeKey,
  statisticsDataIsVisible,
  onShowData,
}) {
  const locale = localStorage.getItem('locale') || 'pt-BR';

  const intl = useIntl();

  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');
  const territories = _.get(baseParams, 'territories');
  const territoryIds = _.map(territories, 'id');

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
    client: clientType === 'custom' ? customClient : undefined
  });
  const classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  const { data: baseData, loading, error: errorBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      classTreeNodeIds: activeClassTreeNodeIds,
      fromYear: 1985,
      toYear: activeYear,
      territoriesIds: territoryIds,
    },
    client: clientType === 'custom' ? customClient : undefined
  });

  const frequencyByAreaData = () => {
    const data = _.get(baseData, 'frequencySeriesAggregated');

    const parsedValues = _.map(data, (item) => {
      const nodeId = _.get(item, 'label');
      const value = _.get(item, 'value');
      const nodeData = _.find(classTreeLevelsList, { id: Number(nodeId) });
      const stringList = _.get(nodeData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const label = _.get(selectedString, 'stringValue');

      return {
        name: label,
        value,
        itemStyle: {
          color: _.get(nodeData, 'color') || '#e74c3c'
        },
        position: _.get(nodeData, 'positionInTree[0]')
      };
    });

    return _.orderBy(parsedValues, 'name', 'asc');
  };

  useEffect(() => {
    const sampleData = _.get(baseData, 'frequencySeriesAggregated');

    if ((!loading && errorBaseData) || _.isEmpty(sampleData)) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }

    if (!loadingClassTreeData && !loading && baseData) {
      setChartsData({
        'frequencyByArea': frequencyByAreaData(),
      });
    }
  }, [baseData, loading, loadingClassTreeData, errorBaseData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const frequencyByArea = () => {
    const values = _.get(chartsData, 'frequencyByArea') || [];
    console.log("🚀 ~ frequencyByArea ~ values:", values)

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }>
          <span>{ intl.formatMessage({id: 'mapbiomas.statistics.burned.last_year.historical_by_year.title'}) }</span>
        </h2>
        <BarChart
          vertical={ false }
          height={ 750 }
          categories={ _.map(values, 'name') }
          series={ [
            {
              name: 'Total',
              data: values,
            }
          ] }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const frequencyByAreaChartData = _.get(chartsData, 'frequencyByArea') || [];
    const frequencyByAreaChart = {
      title: !ageMode ? intl.formatMessage({id: 'mapbiomas.statistics.burned.last_year.historical_by_year.title'}) : intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.area_per_age.title'}),
      columns: _.map(frequencyByAreaChartData, 'name'),
      rows: [
        {
          name: 'Total',
          data: _.map(frequencyByAreaChartData, 'value'),
        }
      ]
    };

    onShowData([
      frequencyByAreaChart,
    ]);
  };

  const renderData = () => {
    if (emptyData) {
      return null;
    }

    return (
      <Fragment>
        { frequencyByArea() }
        <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p><FormattedMessage id="mapbiomas.statistics.processing_data" /></p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
