import { gql } from 'graphql.macro';

export const GET_CLASS_TREE_DATA = gql`
  query GetClassTreeData(
    $classTreeKey: String!,
  ) {
    classTreeByKey(key: $classTreeKey) {
      id
      key
     	i18nStrings {
				language
				stringValue
      }
			mvClassTreeLevelsUi {
        id
        color
        classId
        childrenIds
        positionInTree
        level
        parentId
        defaultName
        classTreeId
        pixelValue
				i18nStrings {
					language
					stringValue
				}
      }
    }
  }
`;

export const GET_BASE_DATA = gql`
  query GetBaseData($territoryIds: [Int!]!, $classTreeId: Int!) {
    coverageClassificationDataList: coverageClassificationDataByTreeList(
      classTreeId: $classTreeId,
			bufferId: null,
			territoryIds: $territoryIds
    ) {
      territoryId
      year
      classTreeNodeId
      areaInHa
    }
  }
`;
