export default {
  'degradation_fragmentation_edge_area': {
    path: 'edgeArea',
    paramKey: 'edgeSize'
  },
  'degradation_fragmentation_fragment_size': {
    path: 'patchSize',
    paramKey: 'patchSize'
  },
  'degradation_fragmentation_isolation': {
    path: 'isolation',
    paramKey: 'distance',
  },
  'degradation_fragmentation_isolation_medium_size': {
    paramKey: 'mediumSize'
  },
  'degradation_fragmentation_isolation_distance': {
    paramKey: 'distance'
  },
  'degradation_fragmentation_isolation_big_size': {
    paramKey: 'bigSize'
  },
  'degradation_fire_frequency': {
    path: 'fireFrequency',
    paramKey: 'fireFrequency'
  },
  'degradation_fire_time': {
    path: 'fireAge',
    paramKey: 'fireAge'
  },
  'degradation_secondary_vegetation_age': {
    path: 'secondaryVegetationAge',
    paramKey: 'secondaryVegetationAge'
  },
};
