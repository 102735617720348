import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import { useIntl ,FormattedMessage } from 'react-intl';

import BarChart from '../../components/BarChart';
import BaseValuesChart from '../../components/BaseValuesChart';

import customClient from '../../../../../../../../core/custom-apollo-client/custom-apollo-client';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
} from './query';

export default function FireAnnualTotalBurnedStatisticsContent({
  clientType,
  activeYear,
  baseParams,
  statisticsDataIsVisible,
  onShowData,
}) {
  const years = [_.first(activeYear), _.last(activeYear)];
  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');
  const territories = _.get(baseParams, 'territories');
  const territoryIds = _.map(territories, 'id');
  const intl = useIntl()

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: 'fire_annual_total_burned'
    },
    client: clientType === 'custom' ? customClient : undefined
  });
  const classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  const filteredActiveClassTreeNodeIds = _.filter(activeClassTreeNodeIds, (nodeId) => {
    const nodeData = _.find(classTreeLevelsList, { id: nodeId });
    const childrenIds = _.get(nodeData, 'childrenIds');

    if (_.isEmpty(childrenIds)) {
      return true;
    } else {
      return !childrenIds.some(id => activeClassTreeNodeIds.indexOf(id) >= 0);
    }
  });

  const { data: baseData, loading, error: errorBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      classTreeNodeIds: filteredActiveClassTreeNodeIds,
      year: years,
      territoriesIds: territoryIds
    },
    skip: loadingClassTreeData,
    client: clientType === 'custom' ? customClient : undefined
  });

  const getTotalBurnedAreaByYearData = () => {
    const data = _.get(baseData, 'calcCoverageByYear[0]');
    const values = _.get(data, 'burnedyear');
    const sortedValues = _.sortBy(values, 'year');

    return {
      categories: _.map(sortedValues, 'year'),
      series: [
        {
          name: 'Total',
          color: '#e74c3c',
          data: _.map(sortedValues, 'total'),
        }
      ]
    };
  };

  useEffect(() => {
    const sampleData = _.get(baseData, 'calcCoverageByYear[0].burnedyear');

    if ((!loading && errorBaseData) || _.isEmpty(sampleData)) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }

    if (!loadingClassTreeData && !loading && baseData) {
      setChartsData({
        'totalBurnedAreaByYear': getTotalBurnedAreaByYearData(),
      });
    }
  }, [baseData, loading, loadingClassTreeData, errorBaseData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const burnedAreaByPeriod = () => {
    const data = _.get(baseData, 'calcCoverageByYear[0]');
    const total = _.get(data, 'totalburnedarea') || 0;
    const maxValue = _.get(data, 'maximun') || 0;
    const averageValue = _.get(data, 'average') || 0;
    const minValue = _.get(data, 'minimun') || 0;
    const averagePercentage = (averageValue / maxValue) * 100;
    const minPercentage = (minValue / maxValue) * 100;

    return (
      <BaseValuesChart
        total={ total }
        maxValue={ maxValue }
        averageValue={ averageValue }
        averagePercentage={ averagePercentage }
        minValue={ minValue }
        minPercentage={ minPercentage }
        title={intl.formatMessage({id: 'mapbiomas.statistics.burned.period_title'})}
        totalTitle={intl.formatMessage({id: 'mapbiomas.statistics.burned.annual_total.summary.total.title'})}
        totalTitleTooltipKey="mapbiomas.statistics.burned.annual_total.summary.total.tooltip"
        maxValueTooltipKey="mapbiomas.statistics.burned.annual_total.summary.max.tooltip"
        averageValueTooltipKey="mapbiomas.statistics.burned.annual_total.summary.average.tooltip"
        minValueTooltipKey="mapbiomas.statistics.burned.annual_total.summary.min.tooltip"
      />
    );
  };

  const totalBurnedAreaByYear = () => {
    const categories = _.get(chartsData, 'totalBurnedAreaByYear.categories');
    const series = _.get(chartsData, 'totalBurnedAreaByYear.series');

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.statistics.burned.total_per_year_title" /></h2>
        <BarChart
          hasAvgMarkLine
          categories={ categories }
          series={ series }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const totalBurnedAreaByYearChartData = _.get(chartsData, 'totalBurnedAreaByYear') || [];
    const totalBurnedAreaByYearChart = {
      title: intl.formatMessage({id: 'mapbiomas.statistics.burned.total_per_year_title'}),
      columns: _.get(totalBurnedAreaByYearChartData, 'categories'),
      rows: _.get(totalBurnedAreaByYearChartData, 'series'),
    };

    onShowData([
      totalBurnedAreaByYearChart,
    ]);
  };

  const renderData = () => {
    if (emptyData) {
      return null;
    }

    return (
      <Fragment>
        { burnedAreaByPeriod() }
        { totalBurnedAreaByYear() }
        <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p><FormattedMessage id="mapbiomas.statistics.processing_data" /></p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
