import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import classnames from 'classnames';

import { useIntl } from 'react-intl';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';

import { GET_BASE_DATA } from './query';

import styles from './YearTimeline.module.scss';

const useStyles = makeStyles((theme) => ({
  headerInfoValue: {
    color: theme.palette.primary.main
  },
}));

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: '4px',
    fontSize: 12,
    color: '#F5F5F9',
    backgroundColor: '#5A5A5A'
  },
  arrow: {
    color: '#5A5A5A'
  },
  popper: {
    zIndex: 4000
  }
}))(Tooltip);

export default function YearTimeline({
  firstYearFixed = false,
  headerIsVisible,
  value: initialValue,
  onChange = () => {},
}) {
  const intl = useIntl()

  const classes = useStyles();
  const { data: baseData } = useQuery(GET_BASE_DATA);
  const rangeLimits = _.get(baseData, 'app.timelineLimitsRange');
  const rangeSpecificValues = _.get(baseData, 'app.timelineSpecificValues');
  const [currentValue, setCurrentValue] = useState(initialValue);
  // const [currentValue, setCurrentValue] = useState(_.last(rangeLimits) || _.last(rangeSpecificValues));
  const [updateKey, setUpdateKey] = useState(_.uniqueId('timeline-'));
  const isRange = _.isArray(initialValue);
  const valueLabel = isRange ? _.join(initialValue, ' - ') : initialValue;

  useEffect(() => setCurrentValue(initialValue), [initialValue]);
  useEffect(() => setUpdateKey(_.uniqueId('timeline-')), [isRange, _.last(rangeLimits), _.last(rangeSpecificValues)]);

  const handleChange = (_event, newValue) => {
    if (!firstYearFixed) {
      setCurrentValue(newValue);
    } else {
      setCurrentValue([_.first(initialValue), _.last(newValue)]);
    }
  };

  const handleChangeComitted = (_event, value) => {
    if (!firstYearFixed) {
      onChange(value);
    } else {
      onChange([_.first(initialValue), _.last(value)]);
    }
  };

  function ValueLabelComponent(props) {
    const { children, index, open, value } = props;

    let isOpen = open;
    let title = value;

    if (index === 0 && firstYearFixed) {
      title = `${ value } - ${intl.formatMessage({id: 'mapbiomas.timeline.fixed_year'})}`;
    }

    if (index === 1) {
      isOpen = true;
      title = `${ value } - ${intl.formatMessage({id: 'mapbiomas.timeline.selected_value'})}`;
    }

    return (
      <CustomTooltip
        arrow
        open={ isOpen }
        placement="top"
        title={ title }
      >
        { children }
      </CustomTooltip>
    );
  }

  let sliderItemsProps = {};
  if (!rangeLimits && !rangeSpecificValues) {
    return null;
  } else if (rangeLimits) {
    sliderItemsProps = {
      step: 1,
      min: _.first(rangeLimits),
      max: _.last(rangeLimits),
      marks: true
    }
  } else {
    sliderItemsProps = {
      step: null,
      min: _.first(rangeSpecificValues),
      max: _.last(rangeSpecificValues),
      marks: rangeSpecificValues.map((rsv) => ({ value: rsv }))
    }
  }

  return (
    <div className={ classnames(styles.wrapper, {
      [styles.wrapperWithHeader]: headerIsVisible,
    }) } id="year-timeline">
      <div className={ styles.timelineInfo }>
        <DateRangeIcon />
        <span className={ classes.headerInfoValue }>{ valueLabel }</span>
      </div>
      <div className={ styles.sliderWrapper }>
        <Slider
          { ...sliderItemsProps }
          key={ `timeline-${ updateKey }` }
          defaultValue={ currentValue || _.last(rangeLimits) || _.last(rangeSpecificValues) }
          value={ currentValue }
          track={ !!isRange }
          valueLabelDisplay="auto"
          ValueLabelComponent={ ValueLabelComponent }
          onChange={ handleChange }
          onChangeCommitted={ handleChangeComitted }
          classes={{
            mark: styles.timelineMark
          }}
        />
      </div>
    </div>
  );
}
