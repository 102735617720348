import React, { useState } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useApolloClient } from '@apollo/react-hooks';
import qs from 'qs';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

import CustomSelect from '../../../../../../components/CustomSelect';

import styles from './DegradationIsolationClassTreeNodesControl.module.scss';

const initialValues = {
  mediumSize: null,
  distance: null,
  bigSize: null,
};

const mediumSizeOptions = [
  { id: 25, label: 'maior ou igual a 25ha' },
  { id: 50, label: 'maior ou igual a 50ha' },
  { id: 100, label: 'maior ou igual a 100ha' },
];

const distanceOptions = [
  { id: 5, label: 'maior ou igual a 5km' },
  { id: 10, label: 'maior ou igual a 10km' },
  { id: 20, label: 'maior ou igual a 20km' },
];

const bigSizeOptions = [
  { id: 100, label: 'maior ou igual a 100ha' },
  { id: 500, label: 'maior ou igual a 500ha' },
  { id: 1000, label: 'maior ou igual a 1000ha' },
];

export default function DegradationIsolationClassTreeNodesControl({
  options,
  value,
  onChange,
}) {
  const client = useApolloClient();
  const [params, setParams] = useState(initialValues);
  const canSubmit = _.size(_.compact(_.values(params))) === 3;

  const updateClientAppState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          ...params
        }
      }
    });
  };

  const updateClientAppBaseParamsState = (params) => {
    updateClientAppState({
      baseParams: {
        __typename: 'AppBaseParamsState',
        ...params
      }
    });
  };

  const handleChange = (param, value) => {
    setParams((prevParams) => ({
      ...prevParams,
      [param]: value
    }));
  };

  const handleSubmit = () => {
    const parsedParams = qs.stringify(params, { arrayFormat: 'repeat' });

    updateClientAppBaseParamsState({
      degradationFragmentationIsolationValue: parsedParams
    });
  };

  const handleReset = () => {
    setParams(initialValues);
    updateClientAppBaseParamsState({
      degradationFragmentationIsolationValue: null
    });
  };

  return (
    <>
      <p className={ styles.baseClassInfo }>Selecione abaixo os 3 parâmetros para ativar a opção de visualizar os dados.</p>
      <FormControl
        variant="outlined"
        className={ styles.formControl }
      >
        <CustomSelect
          defaultOrder
          disableClearable={ false }
          options={ bigSizeOptions }
          value={ _.get(params, 'bigSize') }
          dataKey="bigSize"
          label="Fonte"
          onChange={ handleChange }
          placeholder="Selecione uma opção"
        />
      </FormControl>
      <FormControl
        variant="outlined"
        className={ styles.formControl }
      >
        <CustomSelect
          defaultOrder
          disableClearable={ false }
          options={ distanceOptions }
          value={ _.get(params, 'distance') }
          dataKey="distance"
          label="Distância"
          onChange={ handleChange }
          placeholder="Selecione uma opção"
        />
      </FormControl>
      <FormControl
        variant="outlined"
        className={ styles.formControl }
      >
        <CustomSelect
          defaultOrder
          disableClearable={ false }
          options={ mediumSizeOptions }
          value={ _.get(params, 'mediumSize') }
          dataKey="mediumSize"
          label="Alvo"
          onChange={ handleChange }
          placeholder="Selecione uma opção"
        />
      </FormControl>
      <div className={ styles.actionWrapper }>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={ handleReset }
        >
          Resetar
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={ !canSubmit }
          onClick={ handleSubmit }
        >
          <FormattedMessage id="mapbiomas.user.my_maps.list.actions.show" />
        </Button>
      </div>
      <p className={ styles.baseClassInfoSecondary }>Caso os parâmetros acima não estejam selecionados, por padrão é considerado o alvo 25 e fonte 500.</p>
    </>
  );
}
