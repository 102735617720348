import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  query GetBaseData($classId: Int=41){
    classTreeLevels(id: $classId) {
      id
      defaultName
      i18nStrings {
				language
				stringValue
      }
      classTreeNode {
        classe {
          classesTooltips {
            id
            classesTooltipImages {
              id
              source
              url
              i18nStrings {
								language
								stringValue
              }
            }
            titleStrings {
                language
                stringValue
            }
            bodyStrings {
                language
                stringValue
            }
          }
        }
      }
    }
  }
`
